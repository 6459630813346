<!-- <mat-toolbar class="toolbar-style">
    <div class="row">
        <div class="col-md-12 text-center">
            <img class="akamai-logo-style" src="/assets/akamai-logo.svg">
        </div>
    </div>
</mat-toolbar> -->
<div class="row g-0 text-center">
    <div class="col-sm-6 col-md-8">
        <mat-toolbar class="toolbar-style">
            <div class="row">
                <div class="col-md-12 col-md-8 text-center">
                    <img class="akamai-logo-style" src="../../../assets/akamai-logo.svg">
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div class="col-6 col-md-4 toolbar-style-login">
        <!-- <button type="submit" class="button-color-akamai-login" (click)="loginDemo()">Login</button> -->
        <h4 style="margin-top: 10px;"><a style="text-decoration: none; color: white;" href="https://portal.akamaicentral.com/pages/support-request" target="_blank">Support Request</a></h4>
    </div>
</div>
<div *ngIf="errorFound" class="alert alert-warning alert-dismissible fade show" role="alert">
    {{ errorMessage }}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="closeButton()"></button>
</div>
<!-- <div *ngIf="successFound" class="alert alert-success alert-dismissible fade show" role="alert">
    {{ successMessage }}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="closeButton()"></button>
</div> -->

<div class="container background-color-login">
    <div class="demo-body">
        <div class="row">
            <div class="col-md-6 login-text">
                <p>{{demoName}} Interactive Demo</p>
                <div class="demo-description">
                    <p>{{ demoDescription_a }}</p>
                    <p>{{ demoDescription_b }}</p>
                    <ul>
                        <li>{{ demoDescription_c }}</li>
                        <li>{{ demoDescription_d }}</li>
                        <li>{{ demoDescription_e }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 form-text">
                <form class="example-form" [formGroup]="demoForm" (ngSubmit)="onSubmit()" #myForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">First Name:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput formControlName="firstName" (input)="changefirstname($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Last Name:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput formControlName="lastName" (input)="changelastname($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="demoNameReg === 'apisec'; else noApiSec">
                        <div class="row">
                            <div class="col-md-12">
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noApiSec>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-label class="form-label-style">Username:</mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput formControlName="userName" [readonly]="true">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-label class="form-label-style">Password:</mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput value="" type="password" formControlName="userPwd">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Email:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput value="" type="email" formControlName="userEmail">
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Role:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput value="" formControlName="userRole">
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Company:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput value="" formControlName="userCompany">
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="show_tc_checkbox" class="row">
                        <div class="col-md-12">
                            <section class="example-section">
                                <mat-checkbox class="example-margin" [(ngModel)]="checked" [ngModelOptions]="{standalone: true}">I Agree to Akamai's <a href="https://www.akamai.com/legal/portal-terms" target=”_blank”>Portal Terms of Use</a></mat-checkbox>
                            </section>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">                            
                            <section *ngIf="!createInProgress" class="example-section">
                                <button type="submit" [disabled]="demoForm.invalid || !checked" class="button-color-akamai" mat-flat-button>Submit</button>
                            </section>
                            <section class="example-section" *ngIf="createInProgress">
                                <button type="submit" [disabled]="true" class="button-color-akamai" mat-flat-button><mat-spinner diameter="25"></mat-spinner></button>
                            </section>
                        </div>
                    </div>
                </form>                  
            </div>
        </div>
    </div>    
</div>
